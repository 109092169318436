import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import Axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import './assets/css/global.css'
import 'animate.css';
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css

// Axios.defaults.baseURL = '/api'//线下
Axios.defaults.baseURL = process.env.VUE_APP_API_URL//线上




let app = createApp(App)
app.use(vue3videoPlay);
app.use(ElementPlus)
app.config.globalProperties.$http = Axios
app.use(router)
app.mount('#app')
<template>
  <el-scrollbar height="100vh" noresize>
    <router-view></router-view>
  </el-scrollbar>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
</style>

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/', name: 'home', redirect: '/homePage', component: () => import('@/components/home'),
        children: [
            {
                path: '/homePage', name: 'homePage', components: {
                    default: () => import('@/components/homePage'),
                    footerView: () => import('@/components/footerView')
                }
            },
            {
                path: '/productPage', redirect: '/product1', name: 'productPage', components: {
                    default: () => import('@/components/productPage'),
                    footerView: () => import('@/components/footerView')
                },
                children: [
                    {
                        path: '/product1', name: 'product1', component: () => import('@/components/productPage/product1')
                    },
                    {
                        path: '/product2', name: 'product2', component: () => import('@/components/productPage/product2')
                    },
                    {
                        path: '/product3', name: 'product3', component: () => import('@/components/productPage/product3')
                    }]
            },
            {
                path: '/recruitPage', name: 'recruitPage', components: {
                    default: () => import('@/components/recruitPage'),
                    footerView: () => import('@/components/footerView')
                }
            },
            {
                path: '/usPage', name: 'usPage', components: {
                    default: () => import('@/components/usPage'),
                    footerView: () => import('@/components/footerView')
                }
            },
        ]
    },
    {
        path: '/recruitItem', name: 'recruitItem', redirect: '/recruitItem1', component: () => import('@/components/recruit/recruitItem'),
        children: [
            {
                path: '/recruitItem1', name: 'recruitItem1', components: {
                    default: () => import('@/components/recruit/recruitItem1'),
                    footerView: () => import('@/components/footerView')
                }
            },
        ]
    }
]

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
    scrollBehavior() {

        return {
            el: '#main',
            top: -10,
        }
    }
})
// router.afterEach((to, from, next) => {
//     document.body.scrollTop = -100;
//     document.documentElement.scrollTop = -100;

//     // // 兼容IE
//     window.scrollTo(0, -100);
//     return
// })
export default router